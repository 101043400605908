import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import Meta, { MetaProps } from "../components/Meta"
import PageHeader from "../components/PageHeader"

interface DocumentPageTemplateProps {
  html: string
}

const DocumentPageTemplate: FC<DocumentPageTemplateProps> = ({
  html
}) => (
  <main
    id="document-page"
    className="px-6 py-32"
  >
    <div
      className="w-full max-w-4xl mx-auto markdown"
      dangerouslySetInnerHTML={{
        __html: marked(html)
      }}
    />
  </main>
)

interface DocumentPageProps {
  data: {
    markdownRemark: {
      frontmatter: DocumentPageTemplateProps & {
        meta: MetaProps
      },
      html: string
    }
  }
}

const DocumentPage: React.FC<DocumentPageProps> = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <DocumentPageTemplate
        html={html}
      />
    </>
  )
}

export default DocumentPage

export const DocumentPageQuery = graphql`
  query DocumentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
          css
          js
        }
      }
      html
    }
  }
`
